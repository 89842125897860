<template>
  <b-card no-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
      fixed
    >
      <template #cell(title)="data">
        <div>{{ data.item.title }}</div>
        <div class="text-primary font-small-2">
          {{ data.item.group_name }}
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            variant="flat-primary"
            size="sm"
            :to="$route.path + '/data-list/en/' + data.item.id"
          >
            <FeatherIcon icon="ArchiveIcon" /> İngilizce
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import { BCard, BTable, BButton } from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BButton,
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'BAŞLIK',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '200px' },
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['translate/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('translate/getIndexList')
    },
  },
}
</script>
